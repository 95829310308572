/* eslint-disable no-undef */
import React from "react"
import data from "@components/pageCasosdeExito/data/dataPageRoyalEnfield"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
 
import dataOne from "@components/pageHome/data"
import IntroPageCustomer from "@components/pageCasosdeExito/components/pagesCasos/intro"
import TextStrong from "@components/pageCasosdeExito/components/pagesCasos/textStrong"
import TitleandText from "@components/pageCasosdeExito/components/pagesCasos/titleAndText"
import ModulesCustomer from "@components/pageCasosdeExito/components/pagesCasos/useCases"

const StructureCafae = ({ location }) => {

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  return (
    <div>
      <BannerCookies />
        
      <Header
        path="/casos-de-exito/"
        location={location}
        windowsWidth={windowsWidth}
      />
      <section className="container">
        {/* Start Body Customes */}
        <div className="container__casosexito">
          <IntroPageCustomer data={data.intro} location={location} />
          <br />
          <TitleandText title={data.intro.title5} text={data.intro.text5} />
          <br />
          <TitleandText title={data.intro.title7} text={data.intro.text7} />
          <TextStrong text={data.intro.text8} />
          <img
            loading="lazy"
            src={data.intro.fototwo}
            alt="cliente"
            className="img2__casosexito"
          />
          <TitleandText title={data.intro.title9} text={data.intro.text9} />

          <TextStrong text={data.intro.text10} />
          <TitleandText title={data.intro.title11} text={data.intro.text11} />
          <TextStrong text={data.intro.text12} />
          <div>{data.intro.text13}</div>
        </div>
        {/* End Body Customes */}
        <ModulesCustomer
          data={data.cards}
          datatwo={data.descriptiontwo}
          location={location}
        />
        <div className="container__casosexito" style={{ textAlign: "center" }}>{data.intro.text14}</div>
        <Footer data={dataOne.footer} location={location} />
        <FooterMobile data={dataOne.footer} location={location} />
      </section>
    </div>
  )
}

export default StructureCafae
